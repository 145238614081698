import * as React from "react";
import { Redirect, Route, Switch } from "react-router";

import { AboutPage } from "./containers/AboutPage";
import { ContactPage } from "./containers/ContactPage";
import { ErrorPage } from "./containers/ErrorPage";
import { ForumPage } from "./containers/ForumPage";
import { Header } from "./containers/Header";
import { HomeownerPage } from "./containers/HomeownerPage";
import { HomePage } from "./containers/HomePage";
import { LoginPage } from "./containers/LoginPage/LoginPage";
import { PrivacyPolicyPage } from "./containers/PrivacyPolicyPage";
import { TermsOfUsePage } from "./containers/TermsOfUsePage";
import { ProfessionalsPage } from "./containers/ProfessionalsPage";
//import { ResourcesPage } from "./containers/ResourcesPage";
//import { ACtuneUpPage } from "./containers/ResourcesPage/ACtuneUpPage";
//import { FurnaceTuneUpPage } from "./containers/ResourcesPage/FurnaceTuneUpPage";
//import { GiftPage } from "./containers/ResourcesPage/GiftPage";
//import { ListingCoveragePage } from "./containers/ResourcesPage/ListingCoveragePage";
//import { ReKeyPage } from "./containers/ResourcesPage/ReKeyPage";
import { RedirectPage } from "./containers/RedirectPage";
import { PlanTypeName } from "./constants/TransactionType";
import { Contractors, OrderHeader, OrderPage, OrderSubmittedPage, RenderAccount } from "./components/RouterHelpers";
import { redirects } from "./routes-old";
import { WarrantyLink } from "./components/warranty-link/WarrantyLink";
import { StatusUpdate } from "./components/work-order-landing-page/StatusUpdate";
import { StreemSignup } from "./components/streem/StreemSignup";
import { DirectLogin } from "./components/direct-login/DirectLogin";

/**
 * Route collection that will render one specific header depending on the route.
 *
 * @export
 */
export function headerRoutes() {
    return (
        <Switch>
            <Route path="/(account|claim)" render={(props) => <RenderAccount isHeader {...props} />} />
            <Route exact path="/order/:planType?" component={OrderHeader} />
            <Route exact path="/order/renew/:planType?" component={OrderHeader} />
            <Route exact path="/order/upgrade/:planType?" component={OrderHeader} />
            <Route component={Header} />
        </Switch>
    );
}

/**
 * Route collection that will render one specific body depending on the route.
 *
 * @export
 */
export function routes() {
    return (
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/home-warranty-reviews" render={() => <Redirect to="/#reviews"/>} />
            <Route exact path="/home-warranty-plans" render={() => <Redirect to="/homeowner-warranty#home-warranty-plans" />} />
            <Route exact path="/homeowner-warranty/why-landmark" render={() => <Redirect to="/homeowner-warranty#whylandmark" />} />
            <Route exact path="/homeowner-warranty/reviews" render={() => <Redirect to="/homeowner-warranty#reviews" />} />
            <Route exact path="/home-buyers-warranty/protection-plans" render={() => <Redirect to="/home-buyers-warranty#home-warranty-plans" />} />
            <Route exact path="/home-buyers-warranty/residential-service-contract" render={() => <Redirect to="/home-buyers-warranty#reviews" />} />
            <Route exact path="/home-buyers-warranty/your-local-representative" render={() => <Redirect to="/home-buyers-warranty#your-local-representative" />} />
            <Route exact path="/home-buyers-warranty/real-estate-education-tools" render={() => <Redirect to="/home-buyers-warranty#benefits" />} />
            <Route exact path="/order/homebuyer/renew" render={() => <Redirect to="/account" />} />
            {/* These are for backwards compatiblity from external calls, we don't use these internally to the site any more. */}
            <Route exact path="/order/homeowner" render={() => <Redirect to={`/order/${PlanTypeName.Homeowner}`} />} />
            <Route exact path="/order/homebuyer" render={() => <Redirect to={`/order/${PlanTypeName.RealEstate}`} />} />
            <Route exact path="/order/3" render={() => <Redirect to={`/order/${PlanTypeName.Homeowner}`} />} />
            <Route exact path="/order/2" render={() => <Redirect to={`/order/${PlanTypeName.RealEstate}`} />} />
            {/* Note - End of SEO section */}
            <Route exact path="/about-us" component={AboutPage} />
            <Route path="/(account|claim)" render={(props) => <RenderAccount {...props} />} />
            <Route exact path="/blog/:id" component={ForumPage} />
            <Route exact path="/contact-us" component={ContactPage} />
            <Route exact path="/contractors" component={Contractors} />
            <Route exact path="/employment" to="https://frontdoor.jobs/" />
            <Route exact path="/page-not-found" component={ErrorPage} />
            <Route exact path="/error" component={ErrorPage} />
            <Route exact path="/homeowner-warranty" component={HomeownerPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/home-buyers-warranty" component={ProfessionalsPage} />
            <Route exact path="/order/renew" render={() => <Redirect to={`/account`} />} />
            <Route exact path="/order/:planType/submitted" component={OrderSubmittedPage} />
            <Route exact path="/order/renew/:planType/submitted" component={OrderSubmittedPage} />
            <Route exact path="/order/upgrade/:planType/submitted" component={OrderSubmittedPage} />
            <Route exact path="/order/:planType?" component={OrderPage} />
            <Route exact path="/order/renew/:planType?" component={OrderPage} />
            <Route exact path="/order/upgrade/:planType?" component={OrderPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route exact path="/terms-of-use" component={TermsOfUsePage} />
            <Route exact path="/warranty-link/:policyId" component={WarrantyLink} />
            <Route exact path="/status-update/:workorderId" component={StatusUpdate} />
            <Route exact path="/streem-signup" component={StreemSignup} />
            <Route path="/loginWithUser/:webUserId/:wmUserId" component={DirectLogin} />
            {/*<Route path="/resources" render={resources} />*/}
            <Route exact path="/redirectLegacy" component={RedirectPage} />
            <Route render={redirects} />
        </Switch>
    );
}

/**
 * Blog posts routes. These needed to be seperated to add the fixed blog routes without breaking the routing.
 *
 * @export
 */
//export function resources() {
//    return (
//        <Switch>
//            {/* Place all category reroutes here */}
//            <Route exact path="/resources/property-managers" render={() => <Redirect to="/resources/property-management" /> } />

//            <Route exact path="/resources/:category?" component={ResourcesPage} />

//            {/* Place all articles reroutes, that match the /resources/:category/:title/:forumId/:id format, here */}
//            <Route exact path="/resources/hvac/how-to-pick-filters-for-hvac-from-home-warranty-company/4/41" render={() => <Redirect to="/resources/hvac/how-to-pick-the-best-filter-for-your-hvac-unit/4/41" /> } />
//            <Route exact path="/resources/hvac/how-to-clean-or-replace-your-furnace-filter/4/42" render={() => <Redirect to="/resources/hvac/how-to-clean-or-replace-your-hvac-filters/4/42" />} />
//            <Route exact path="/resources/appliance-warranty/enter-to-win-a-kitchen-gadget-from-landmark-home-warranty/5/54" render={() => <Redirect to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />} />
//            <Route exact path="/resources/appliance-warranty/kitchen-gadget-giveaway-winner-from-landmark-home-warranty/5/60" render={() => <Redirect to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />} />
//            <Route exact path="/resources/appliance-warranty/the-winner-of-the-ring-is/5/56" render={() => <Redirect to="/resources/appliance-warranty/the-3-best-smart-gadgets-for-home-protection/5/51" />} />
//            <Route exact path="/resources/appliance-warranty/enter-to-win-a-ring-for-free-from-landmark-home-warranty/5/52" render={() => <Redirect to="/resources/appliance-warranty/the-3-best-smart-gadgets-for-home-protection/5/51" />} />
//            <Route exact path="/resources/buying-selling-house/nine-clever-money-making-tricks-to-offset-your-mortgage/7/122" render={() => <Redirect to="/resources/buying-selling-house/" />} />
//            <Route exact path="/resources/home-repairs-maintenance/remodeling-your-home-know-about-vocs/6/73" render={() => <Redirect to="/resources/home-repairs-maintenance/" />} />
//            <Route exact path="/resources/real-estate-tips/snip-snip-win-a-trip-april-2016/8/225" render={() => <Redirect to="/resources/real-estate-tips/" />} />
//            <Route exact path="/resources/real-estate-tips/tax-deductible-closing-gifts-for-re-pros/8/158" render={() => <Redirect to="/resources/real-estate-tips/" />} />
//            <Route exact path="/resources/plumbing/how-your-water-heater-works/12" render={() => <Redirect to="/resources/plumbing/how-a-water-heater-works/2/12"/>} />
//            <Route exact path="/resources/22/plumbing/how-to-descale-your-showerhead" render={() => <Redirect to="/resources/plumbing/how-to-descale-your-showerhead/2/22" />} />
//            <Route exact path="/resources/27/plumbing/do-it-yourself-toilet-repairs" render={() => <Redirect to="/resources/plumbing/do-it-yourself-toilet-repairs/2/27" />} />
//            <Route exact path="/resources/plumbing/diagnose-your-plumbing-problems/28" render={() => <Redirect to="/resources/plumbing/diagnose-your-plumbing-problems/2/28" />}/>
//            <Route exact path="/resources/24/plumbing/descale-your-pipes-overnight" render={() => <Redirect to="/resources/plumbing/descale-your-pipes-overnight/2/24" />} />
//            <Route exact path="/resources/recent-home-warranty-resources/diy-fix-a-rust-spot-on-your-tub/undefined/547" render={() => <Redirect to="/resources/plumbing/diy-fix-a-rust-spot-on-your-tub/2/547" />} />
//            <Route exact path="/resources/appliance-warranty/unlocking-the-secrets-of-laundry/5/58" render={() => <Redirect to="/resources/appliance-warranty/what-do-the-laundry-symbols-mean-unlocking-laundry-tips-and-tricks/5/58" />} />
//            <Route exact path="/resources/home-warranty-education/how-to-use-the-quick-order-tool-to-order-a-home-warranty/10/4739" render={() => <Redirect to="/resources/home-warranty-education/how-to-use-the-quick-order-tool-to-order-a-home-warranty/10/3742" />} />
//            <Route exact path="/resources/home-warranty-education/home-warranty-plumbing-plan/10/4740" render={() => <Redirect to="/resources/home-warranty-education/home-warranty-plumbing-plan/10/3761" />} />
//            <Route exact path="/resources/home-warranty-education/water-heater-warranty-plan/10/4741" render={() => <Redirect to="/resources/home-warranty-education/water-heater-warranty-plan/10/3762" />} />
//            <Route exact path="/resources/home-warranty-education/hvac-warranty-plan/10/4742" render={() => <Redirect to="/resources/home-warranty-education/hvac-warranty-plan/10/3763" />} />
//            <Route exact path="/resources/landmark-home-warranty-news/landmark-home-warranty-wins-fourth-award-for-service-and-coverage-for-2019/11/4743" render={() => <Redirect to="/resources/landmark-home-warranty-news/landmark-home-warranty-wins-fourth-award-for-service-and-coverage-for-2019/11/3764"/> } />
//	    	<Route exact path="/resources/lifestyle-decor/make-your-own-halloween-apothecary-bottles-free-printable-labels/50/4744" render={() => <Redirect to="/resources/lifestyle-decor/make-your-own-halloween-apothecary-bottles-free-printable-labels/50/3765" />} />
//            <Route exact path="/resources/lifestyle-decor/diy-spooky-cheesecloth-ghosts-for-halloween/50/4745" render={() => <Redirect to="/resources/lifestyle-decor/diy-spooky-cheesecloth-ghosts-for-halloween/50/3766" />} />
//            <Route exact path="/resources/appliance-warranty/how-to-load-a-dishwasher-the-right-way/5/4746" render={() => <Redirect to="/resources/appliance-warranty/how-to-load-a-dishwasher-the-right-way/5/3767"/>} />
//		    <Route exact path="/resources/appliance-warranty/prepare-your-kitchen-appliances-for-holiday-meals/5/4747" render={() => <Redirect to="/resources/appliance-warranty/prepare-your-kitchen-appliances-for-holiday-meals/5/3768" />} />

//            <Route exact path="/resources/:category/:title/:forumId/:id" component={ForumPage} />

//            {/* Place all category subtopic reroutes, that match /resources/:category/:title/:forumId? format, here.  */}
//            <Route exact path="/resources/hvac/ac-tune-up" component={ACtuneUpPage} />
//            <Route exact path="/resources/hvac/furnace-tune-up" component={FurnaceTuneUpPage} />
//            <Route exact path="/resources/buying-selling-house/re-key-gift" component={GiftPage} />
//            <Route exact path="/resources/real-estate-tips/listing-coverage" component={ListingCoveragePage} />
//            <Route exact path="/resources/home-warranty-education/re-key" component={ReKeyPage} />

//            <Route exact path="/resources/:category/:title/:forumId?" render={({match}) => <Redirect to={`/resources/${match.params.category}`} />} />
//        </Switch>
//    );
//}


