import * as Landmark from "models/landmark-api";
import * as React from "react";
import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { getStates } from "../selectors/reference.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { handleClick } from "../utils/link";
import { getCanOrder } from "selectors/order.selectors";

import "./Footer.scss";

interface Props {
    states?: Landmark.State[];
    canOrder?: boolean;
    actions?: {
        router: RouterActionCreators
    };
}

interface State {
    /**
     * Flag representing whether this currently being rendered on client vs server.
     *
     * @type {boolean}
     * @memberof State
     */
    isClientSide?: boolean;
    showBrands?: boolean;
}

@connect(
    state => ({
        states: getStates(state),
        canOrder: getCanOrder(state),
    }),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class Footer extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isClientSide: false,
            showBrands: false ,
        };
        this.renderMarketStates = this.renderMarketStates.bind(this);
    }

    /**
     * This function is only ran on client side, so we can no for certain that client side rendered components are ready to render after this function is called.
     *
     * @memberof Footer
     */
    componentDidMount() {
        this.setState(
            { isClientSide: true, showBrands: false }
        );
    }

    renderMarketStates() {
        return this.state.isClientSide ?
            this.props.states
                .filter(s => s.isMarketState)
                .map((s, i) => {
                    if (i === 0) {
                        return s.description;
                    }
                    else {
                        if (i === this.props.states.length - 1) {
                            return `, and ${s.description}`;
                        }
                        return `, ${s.description}`;
                    }
                }) :
            "";
    }

    showBrands(showBrands: boolean) {
        if (showBrands) {
            this.setState({ showBrands: false });
        }
        else {
            this.setState({ showBrands: true });
        }
    }

    handleCanOrder = (e, $this) => {
        e.preventDefault();
        if (this.props.canOrder) {
            $this.props.actions.router.push(e.target.getAttribute("href"));
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row footer-row">
                        <ul className="footer-nav">
                            <li><a href="/homeowner-warranty" onClick={e => this.handleCanOrder(e, this)}>Homeowners</a></li>
                            <li><a href="/home-buyers-warranty" onClick={e => this.handleCanOrder(e, this)}>Professionals</a></li>
                            <li><a href="/contractors" onClick={e => handleClick(e, this)}>Contractors</a></li>
                            {/*<li><a href="/resources" onClick={e => handleClick(e, this)}>Resources</a></li>*/}
                            <li><a href="/contact-us" onClick={e => handleClick(e, this)}>Contact</a></li>
                            <li><a href="https://frontdoor.jobs/" target="_blank" rel="nofollow">Employment</a></li>
                            <li>
                                <a onClick={e => this.showBrands(this.state.showBrands)}>our brands</a>
                                <i className="brandBox" data-icon="{">
                                    { this.state.showBrands ?
                                        <ul>
                                            <li>
                                                <a href=" https://www.ahs.com/" target="blank">American Home Shield</a>
                                            </li>
                                            <li>
                                                <a href="https://www.oneguardhomewarranty.com/" target="blank">Oneguard</a>
                                            </li>
                                            <li>
                                                <a href="http://www.onlinehsa.com/" target="blank">HSA Home Warranty</a>
                                            </li>
                                        </ul>:
                                        null
                                    }
                                </i>
                            </li>
                        </ul>
                        <ul className="footer-links">
                            <li><a href="https://twitter.com/LandmarkHW" data-icon="g" target="_blank" rel="nofollow"></a></li>
                            <li><a href="https://www.facebook.com/LandmarkHomeWarranty/" data-icon="e" target="_blank" rel="nofollow"></a></li>
                            <li><a href="https://plus.google.com/+LandmarkHomeWarranty" data-icon="f" target="_blank" rel="nofollow"></a></li>
                        </ul>
                    </div>
                    <hr />
                    <div className="row">
                        <p className="terms">
                            Landmark provides comprehensive home warranties for homes in {this.renderMarketStates()}
                        </p>
                        <div className="privacy">
                        <a  className="privacy"
                            href="/terms-of-use"
                            onClick={e => {
                            e.preventDefault();
                            this.props.actions.router.push("/terms-of-use");
                            window.scrollTo(0, 0);
                        }}>Terms of Use</a>
                        <a  className="privacy" href="/privacy-policy"
                            onClick={e => {
                            e.preventDefault();
                            this.props.actions.router.push("/privacy-policy");
                            window.scrollTo(0, 0);
                        }}>Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
