import * as React from "react";
import environment from "../environment";
import { headerRoutes, routes } from "../routes";
import { getTealiumScript/*, getJQueryScript*/ } from "../tracking";
import Confirmation from "./Confirmation";
import { Meta } from "./Meta";
import WaitIndicator from "./WaitIndicator";

export class Layout extends React.Component {
    render() {
        return (
            <div style={{ width: "100%", height: "100%", backgroundColor: "rgb(17,62,91)" }}>
                <Meta
                    canonical="/"
                    description="Landmark provides affordable and comprehensive home warranty service in Arizona, Idaho, Nevada, Oregon, Texas, and Utah. Request a quote today."
                    imageAlt="Landmark Home Warranty"
                    imageUrl={require("../content/images/importantResources/landmark-home-warranty-news.jpg")}
                    openGraph={{ siteName: "Landmark", locale: "en_US", type: "website", imageWidth: 300, imageHeight: 274 }}
                    title="Home Warranty Service Provider | Landmark Home Warranty"
                    twitter={{ card: "summary", site: "@LandmarkHW" }}>
                    {!environment.isProduction && <meta name="robots" content="noindex" />}
                    <meta charSet="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="msvalidate.01" content="BE641D5290FB83BFCDC26F8FFAAE476A" />
                    <meta name="google-site-verification" content={environment.googleSiteVerificationKey} />
                    <link rel="manifest" href={environment.publicUrl + "/manifest.json"} />
                    <link rel="shortcut icon" href={environment.publicUrl + "/favicon.ico"} />
                    {/** environment.isProduction && getTrackingScripts() */}
                    {/* environment.canUseABtesting && getABtestingScripts()*/}
                    {getTealiumScript()}
                    {/*getJQueryScript()*/}
                </Meta>
                <WaitIndicator />
                <Confirmation />
                <div className="layout" style={{maxWidth: "1400px", marginLeft: "auto", marginRight: "auto"}}>
                    {window.location.pathname ?
                     window.location.pathname.indexOf("warranty-link") === 1 || window.location.pathname.indexOf("streem-signup") ===1?
                     null : headerRoutes() :
                     headerRoutes()
                    }
                    {routes()}
                </div>
            </div>
        );
    }
}
